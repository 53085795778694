import { Card, Input } from '@/components'

import { useSoftlead } from './useSoftlead'

import { validators } from './validators'

import styles from './Softlead.module.scss'

export const Softlead = ({ control }) => {
  const { onBlurField } = useSoftlead()

  return (
    <>
      <Input.Cnpj
        id="cnpj"
        name="cnpj"
        label="CNPJ da minha empresa"
        placeholder="XX.XXX.XXX/0001-XX"
        control={control}
        rules={validators.cnpj}
        onBlur={onBlurField}
      />

      <Input.Text
        id="name"
        name="name"
        label="Nome e sobrenome"
        placeholder="João da Silva"
        control={control}
        rules={validators.name}
        onBlur={onBlurField}
      />

      <Input.Email
        id="email"
        name="email"
        label="Email"
        placeholder="email@example.com"
        control={control}
        rules={validators.email}
        onBlur={onBlurField}
      />

      <Input.Phone
        id="phone"
        name="phone"
        label="Telefone"
        placeholder="(XX) 9XXXX-XXXX"
        control={control}
        rules={validators.phone}
        onBlur={onBlurField}
      />

      <Card size="xs" bgColor="gray" className={styles.softlead__terms}>
        <span>
          Ao continuar você autoriza que os dados fornecidos sejam
          utilizados&nbsp;
        </span>
        <span>
          com a finalidade específica de ser contatado por e-mail,
          telefone,&nbsp;
        </span>
        <span>
          Whatsapp ou SMS, para recebimento de ofertas de produtos e
          serviços&nbsp;
        </span>
        <span>da Equifax BoaVista.&nbsp;</span>
      </Card>
    </>
  )
}
