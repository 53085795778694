import { Card, TooltipHint } from '@/components'

import { Checkbox } from '@/components/Form/ReactHookForm'

import * as styles from './AdditionalServices.module.scss'

export const AdditionalServices = ({ stepName, control, customClass }) => {
  return (
    <Card
      withBorder
      size="medium"
      customClass={`${styles.additionalServices} ${customClass}`}
    >
      <div className={styles.additionalServices__checkbox}>
        <Checkbox
          size="small"
          name="plan_opt_in"
          label="Notificação e Negativação"
          control={control}
          id={`${stepName}-additionalServices-checkbox`}
        />

        <TooltipHint
          id={`${stepName}-tooltip-button`}
          text="Sem alteração do valor do plano. Sujeito a análise."
        />
      </div>

      <p>
        Declaro que tenho interesse nos serviços de Notificação e Negativação de
        clientes.
      </p>
    </Card>
  )
}
