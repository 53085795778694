'use client'

import { Controller } from 'react-hook-form'

import Radio from '../../Vanilla/Radio'

const RadioField = ({
  name,
  label,
  control,
  value,
  rules = null,
  customClass,
  onChange = (e) => e
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={false}
      render={({ field }) => (
        <Radio
          name={name}
          value={value}
          label={label}
          checked={field.value == value}
          customClass={customClass}
          onChange={(event) => {
            field.onChange(event)
            onChange(name, field.value, event)
          }}
        />
      )}
    />
  )
}

export default RadioField
