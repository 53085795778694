import {
  WHATSAPP_BASE_URL,
  WHATSAPP_PHONE_NUMBERS,
  WHATSAPP_URL_PARAMS
} from './whatsapp'

export const CUSTOM_PLAN_PRICE = 1

export const RECOMMENDED_PLAN_PRICE = 249.9

export const INTERCONNECT_BOX_DETAILS = { 'Valor fixo': 2.95 }

export const CUSTOM_PLAN = {
  type: 'postpaid',
  name: 'Avançado',
  plan_price: 1,
  slug_name: 'personalizado',
  title: 'Avançado',
  text: (
    <>
      Soluções personalizadas para atender às necessidades exclusivas da sua
      empresa.
      <br />
      Para você que:
    </>
  ),
  details: [
    'Quer mais consultas',
    'Quer entender melhor',
    'Quer um plano sob medida'
  ],
  url: `${WHATSAPP_BASE_URL}${WHATSAPP_PHONE_NUMBERS['organic']}${WHATSAPP_URL_PARAMS}`
}

export const AFFILIATE_PLAN = {
  type: 'postpaid',
  is_affiliate: true,
  is_recommended: false,
  name: 'Master',
  slug_name: 'master',
  monthly_consultations: 80,
  plan_price: 600,
  discount: 'Economize 33% por consulta',
  acerta_unit_value: { essencial: 8.61, mais: 12.89, completo: 16.2 },
  define_unit_value: { risco: 6.89, negocio: 16.2, limite: 24.3 },
  negativation_unit_value: { electronic_notice: 3.19, negativation: 9.56 }
}

export const PRE_PAID_PLANS = [
  {
    id: 'pre-1',
    type: 'prepaid',
    label: 'Recarga',
    highlight_text: 'R$ 40,00',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=75'
  },
  {
    id: 'pre-2',
    type: 'prepaid',
    label: 'Recarga',
    highlight_text: 'R$ 70,00',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=76'
  },
  {
    id: 'pre-3',
    type: 'prepaid',
    label: 'Recarga',
    highlight_text: 'R$ 130,00',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=77'
  },
  {
    id: 'pre-4',
    type: 'prepaid',
    label: 'Recarga',
    highlight_text: 'R$ 250,00',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=78'
  },
  {
    id: 'pre-5',
    type: 'prepaid',
    label: 'Recarga',
    highlight_text: 'R$ 500,00',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=205'
  }
]

export const POST_PAID_PLANS = [
  {
    type: 'postpaid',
    name: 'Econômico',
    slug_name: 'economico',
    monthly_consultations: 10,
    plan_price: 99.9,
    acerta_unit_value: { essencial: 12.86, mais: 19.65, completo: 24.2 },
    define_unit_value: { risco: 10.29, negocio: 24.2, limite: 36.3 },
    negativation_unit_value: { electronic_notice: 3.61, negativation: 10.8 }
  },
  {
    type: 'postpaid',
    name: 'Mais',
    slug_name: 'mais',
    monthly_consultations: 16,
    plan_price: 149.9,
    discount: 'Economize 9% por consulta',
    acerta_unit_value: { essencial: 11.69, mais: 17.68, completo: 22 },
    define_unit_value: { risco: 9.35, negocio: 22, limite: 33 },
    negativation_unit_value: { electronic_notice: 3.5, negativation: 10.47 }
  },
  {
    type: 'postpaid',
    name: 'Elite',
    slug_name: 'elite',
    badge_text: 'Mais vendido',
    monthly_consultations: 29,
    plan_price: RECOMMENDED_PLAN_PRICE,
    discount: 'Economize 17% por consulta',
    acerta_unit_value: { essencial: 10.63, mais: 15.91, completo: 20 },
    define_unit_value: { risco: 8.5, negocio: 20, limite: 30 },
    negativation_unit_value: { electronic_notice: 3.4, negativation: 10.16 }
  },
  {
    type: 'postpaid',
    name: 'Exclusivo',
    slug_name: 'exclusivo',
    monthly_consultations: 50,
    plan_price: 399,
    discount: 'Economize 25% por consulta',
    acerta_unit_value: { essencial: 9.56, mais: 14.32, completo: 18 },
    define_unit_value: { risco: 7.65, negocio: 18, limite: 27 },
    negativation_unit_value: { electronic_notice: 3.29, negativation: 9.85 }
  }
]

export const PLANS_DATA = { prepaid: PRE_PAID_PLANS, postpaid: POST_PAID_PLANS }
