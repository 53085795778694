'use client'

import { useState } from 'react'

import _formatters from '@/utils/formatters'

import { ActionToggler, Button, HighlightTitle } from '..'

import { Badges, Details } from './components'

import styles from './Plan.module.scss'

export const Plan = ({
  plan,
  hideCTA = false,
  customClass,
  onClickCTA = () => {}
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  return (
    <div className={`${styles.plan} ${customClass}`}>
      <Badges
        variant="first"
        discount={plan.discount}
        badgeText={plan.badge_text}
      />

      <div className={styles.plan__content}>
        <div className={styles.plan__priceInfo}>
          <p className={styles.plan__label}>
            Plano de <br />
            <b className={styles.plan__price}>
              {_formatters.toCurrency(plan.plan_price)}
            </b>{' '}
            <span>/ mês</span>
          </p>

          <Badges
            variant="second"
            discount={plan.discount}
            badgeText={plan.badge_text}
          />
        </div>

        <HighlightTitle
          size="xs"
          icon="equifax-search-check"
          text={
            <>
              Ideal para <b>{plan.monthly_consultations} consultas</b> por mês
            </>
          }
        />

        <ActionToggler
          size="small"
          leftIcon="equifax-info"
          rightIcon="equifax-arrow-down"
          text="Ver detalhes"
          id={`plan-seeDetails-button--${plan.plan_price}`}
          isExpanded={isDetailsOpen}
          onClick={() => setIsDetailsOpen(!isDetailsOpen)}
        />

        <Details plan={plan} isExpanded={isDetailsOpen} />

        {!hideCTA && (
          <Button
            type="submit"
            className={styles.plan__cta}
            id={`plan-wantToHire-button--${plan.plan_price}`}
            onClick={() => onClickCTA(plan)}
          >
            Quero contratar
          </Button>
        )}
      </div>
    </div>
  )
}
