import { Badge } from '@/components'

import styles from './Badges.module.scss'

export const Badges = ({ discount, badgeText, variant }) => {
  return (
    <div className={`${styles[`badges--${variant}`]}`}>
      <Badge
        color="green"
        text={discount}
        customClass={`${styles.badges__discount} ${!discount && styles['badges__discount--empty']}`}
      />

      {badgeText && (
        <Badge
          round
          color="red"
          icon="equifax-stars"
          text={badgeText}
          customClass={styles.badges__recommended}
        />
      )}
    </div>
  )
}
