import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'
import { removeWhiteSpaces } from '@equifax-ui/utils/formatters/removeWhiteSpaces'

import { useProposalContext } from '@/context/ProposalContext'

import { useStepHandler } from '@/hooks/useStepHandler'

import { API_FLOW_LABEL, SOFTLEAD_LABEL } from '@/constants'
import { E2E_FLOW_DATA } from '@/constants/steps'

export const useProposal = () => {
  const [hasError, setHasError] = useState(false)

  const { createSoftlead } = useStepHandler('softlead')
  const { isLoading, setIsLoading } = useProposalContext()

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    const parsedSoftleadData =
      JSON.parse(sessionStorage.getItem(SOFTLEAD_LABEL)) || {}

    reset(parsedSoftleadData)
    setIsLoading(false)
  }, [])

  const onCatch = () => {
    setHasError(true)
    setIsLoading(false)
  }

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      cnpj: removeSpecialChars(data.cnpj),
      phone: removeWhiteSpaces(removeSpecialChars(data.phone))
    }

    sessionStorage.removeItem(API_FLOW_LABEL)

    await createSoftlead({ payload, flowData: E2E_FLOW_DATA, onCatch })
  }

  return {
    control,
    isValid,
    isLoading,
    hasError,
    setHasError,
    onSubmit,
    handleSubmit
  }
}
