import _validators from '@/utils/validators'

import { triggerTracrTrack } from '@/hooks/useTracking'

import { SOFTLEAD_LABEL } from '@/constants'

export const useSoftlead = () => {
  const onBlurField = ({ target: { name, value } }) => {
    if (_validators[name](value)) {
      const softleadData = sessionStorage.getItem(SOFTLEAD_LABEL)
      const parsedSoftleadData = JSON.parse(softleadData) || {}

      const newSoftleadData = { ...parsedSoftleadData, [name]: value }

      sessionStorage.setItem(SOFTLEAD_LABEL, JSON.stringify(newSoftleadData))

      if (name === 'email') triggerTracrTrack('formInteracted', newSoftleadData)
    }
  }

  return {
    onBlurField
  }
}
