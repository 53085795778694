'use client'

import { Button, If, SkeletonLoader, Softlead } from '@/components'

import { StepError } from '..'

import { useProposal } from './useProposal'

import styles from './Proposal.module.scss'

export const Proposal = () => {
  const {
    control,
    isValid,
    isLoading,
    hasError,
    setHasError,
    onSubmit,
    handleSubmit
  } = useProposal()

  return (
    <div id="proposal" className={styles.proposal}>
      <If
        condition={isLoading}
        renderIf={<SkeletonLoader.Step />}
        renderElse={
          hasError ? (
            <StepError setHasError={setHasError} />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2 className={styles.proposal__title}>
                Contrate seu plano Pós-pago
              </h2>

              <Softlead control={control} />

              <Button
                type="submit"
                id="softlead-continue-button"
                disabled={!isValid}
              >
                Continuar
              </Button>
            </form>
          )
        }
      />
    </div>
  )
}
