'use client'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { usePlans } from '@/hooks/usePlans'

import { Plan, Radio } from '..'

import styles from './PlanList.module.scss'

export const PlanList = ({
  children,
  control,
  customPlans,
  onSelectPlan,
  onClickCTA
}) => {
  const { postpaidPlans } = usePlans()
  const plans = customPlans || postpaidPlans

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <div className={styles.plans}>
      {plans.map((plan) => (
        <div
          key={`plan-${plan.plan_price}`}
          className={styles.plans__item}
          id={`plan-selectable-card--${plan.plan_price}`}
          onClick={() => isMobile && onSelectPlan(plan)}
        >
          <div className={styles.plans__input}>
            <Radio
              name="plan_price"
              control={control}
              value={plan.plan_price}
            />
          </div>

          <Plan
            plan={plan}
            control={control}
            customClass={styles.plans__plan}
            onSelectPlan={onSelectPlan}
            onClickCTA={onClickCTA}
          />
        </div>
      ))}

      {children}
    </div>
  )
}
